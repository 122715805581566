import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce, registarID } from "../../../utils/Utils";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import MessageType from "../../../infrastructure/MessageType";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { PrikaziPoljaZaPretraguType } from "../../unos/registar/RegistarLogical";
import { brojRezultatePretrageOptions } from "../../../utils/EnumBrojRezultataPretrage";
import EnumPol from "../../../utils/EnumPol";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { findBrojRezultataByCode } from "../../../utils/EnumBrojRezultataPretrage";
export default function PacijentSpajanjeList() {
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowPacijentZaSpajanje, setSelectedRowPacijentZaSpajanje] = useState<any>([]);
  const [selectedRowPacijentSpojen, setSelectedRowPacijentSpojen] = useState<any>([]);

  const [selectedPacijent, setSelectedPacijent] = useState();
  const [selectedPacijentZaSpajanje, setSelectedPacijentZaSpajanje] = useState();
  const [selectPacijentSpojen, setSelectPacijentSpojen] = useState();

  const [pacijentiList, setPacijentiList] = useState();
  const [pacijentiZaSpajanjeList, setPacijentiZaSpajanjeList] = useState();

  const [pacijentiSpojeniList, setPacijentiSpojeniList] = useState();
  const [unosiPoljaZaPretraguPacijenta, setUnosiPoljaZaPretraguPacijenta] = useState<any>({ brojRezultatePretrage: findBrojRezultataByCode("100") });
  const [unosiPoljaZaPretraguPacijentaZaSpajanje, setUnosiPoljaZaPretraguPacijentaZaSpajanje] = useState<any>({ brojRezultatePretrage: findBrojRezultataByCode("100") });
  const [unosiPoljaZaPretraguSpojeni, setUnosiPoljaZaPretraguPacijentaSpojeni] = useState<any>({ brojRezultatePretrage: findBrojRezultataByCode("100") });

  const [prikaziPoljaZaPretragu, setPrikaziPoljaZaPretragu] = useState<PrikaziPoljaZaPretraguType[]>([]);

  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    fetchData();
  });

  const optionsPol = [
    { name: Labels.LABEL_ODABERITE_POL, code: EnumPol.ODABERITE_POL },
    { name: Labels.LABEL_ZENSKI, code: EnumPol.ZENSKI },
    { name: Labels.LABEL_MUSKI, code: EnumPol.MUSKI },
  ];

  const fetchData = () => {
    axios
      .post(
        Endpoint.PACIJENT_SIMPLE_SEARCH,
        {
          registarID: registarID,
          brojRezultatePretrage: 100,
        },
        axiosConfig(authData.token)
      )
      .then((pacijentCollection) => {
        const { pacijenti, parametri } = pacijentCollection.data;
        setPacijentiList(pacijenti);
        setPacijentiZaSpajanjeList(pacijenti);
        const prikaziPoljaZaPretraguList: PrikaziPoljaZaPretraguType[] = [
          { type: "text", name: "ime", visible: parametri.kriterijumZaPretraguIme, placeholder: Labels.PLACEHOLDER_IME },
          { type: "text", name: "prezime", visible: parametri.kriterijumZaPretraguPrezime, placeholder: Labels.PLACEHOLDER_PREZIME },
          { type: "text", name: "jmbg", visible: parametri.kriterijumZaPretraguJMBG, placeholder: Labels.PLACEHOLDER_JMBG },
          { type: "text", name: "lbo", visible: parametri.kriterijumZaPretraguLBO, placeholder: Labels.PLACEHOLDER_LBO },
          { type: "number", name: "godinaOd", visible: parametri.kriterijumZaPretraguGodinaOd, placeholder: Labels.PLACEHOLDER_GODINA_OD },
          { type: "number", name: "godinaDo", visible: parametri.kriterijumZaPretraguGodinaDo, placeholder: Labels.PLACEHOLDER_GODINA_DO },
          { type: "drop", name: "pol", visible: parametri.kriterijumZaPretraguPol, placeholder: Labels.PLACEHOLDER_POL },
        ];
        setPrikaziPoljaZaPretragu(prikaziPoljaZaPretraguList.filter((polje: PrikaziPoljaZaPretraguType) => polje.visible));
      });
    axios.post(Endpoint.PACIJENT_SPOJENI_SEARCH, { brojRezultatePretrage: 100 }, axiosConfig(authData.token)).then((pacijentCollection) => {
      setPacijentiSpojeniList(pacijentCollection.data.pacijenti);
    });
  };

  const pretragaPacijenta = (unosiPoljaZaPretragu: any, setPacijentiList: any) => {
    const paramsPretraga = {
      ime: unosiPoljaZaPretragu?.ime ?? "",
      prezime: unosiPoljaZaPretragu?.prezime ?? "",
      godinaOd: unosiPoljaZaPretragu?.godinaOd ?? "",
      godinaDo: unosiPoljaZaPretragu?.godinaDo ?? "",
      pol: unosiPoljaZaPretragu?.pol ?? "",
      jmbg: unosiPoljaZaPretragu?.jmbg ?? "",
      lbo: unosiPoljaZaPretragu?.lbo ?? "",
      brojRezultatePretrage: unosiPoljaZaPretragu?.brojRezultatePretrage?.code,
    };
    axios
      .post(
        Endpoint.PACIJENT_SIMPLE_SEARCH,
        {
          ...paramsPretraga,
          registarID: registarID,
        },
        axiosConfig(authData.token)
      )
      .then((pacijentCollection) => {
        setPacijentiList(pacijentCollection.data.pacijenti);
      });
  };

  const pretragaPacijentaSpojeni = (unosiPoljaZaPretragu: any) => {
    const paramsPretraga = {
      ime: unosiPoljaZaPretragu?.ime ?? "",
      prezime: unosiPoljaZaPretragu?.prezime ?? "",
      godinaOd: unosiPoljaZaPretragu?.godinaOd ?? "",
      godinaDo: unosiPoljaZaPretragu?.godinaDo ?? "",
      pol: unosiPoljaZaPretragu?.pol ?? "",
      jmbg: unosiPoljaZaPretragu?.jmbg ?? "",
      lbo: unosiPoljaZaPretragu?.lbo ?? "",
      brojRezultatePretrage: unosiPoljaZaPretragu?.brojRezultatePretrage?.code,
    };
    axios
      .post(
        Endpoint.PACIJENT_SPOJENI_SEARCH,
        {
          ...paramsPretraga,
          registarID: registarID,
        },
        axiosConfig(authData.token)
      )
      .then((pacijentCollection) => {
        setPacijentiSpojeniList(pacijentCollection.data.pacijenti);
      });
  };

  const spojiPacijente = (pacijentNovi: any, pacijentStari: any) => {
    if (!(pacijentNovi && pacijentStari)) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SPAJANJA_PACIJENTA, "");
      return;
    }
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.PACIJENT_SPOJI, { pacijentNoviID: pacijentNovi.keyPacijent, pacijentStariID: pacijentStari.keyPacijent }, axiosConfig(authData.token))
        .then(() => {
          showGrowlMessage(
            MessageType.SUCCESS,
            Labels.TITLE_MESSAGES_USPESNO_PACIJENT_SPAJANJE,
            pacijentNovi.ime + Labels.SPACE + pacijentNovi.prezime + ", " + pacijentStari.ime + Labels.SPACE + pacijentStari.prezime
          );
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SPAJANJA_PACIJENTA, "");
        })
        .finally(() => {
          setSelectedPacijent(undefined);
          setSelectedPacijentZaSpajanje(undefined);
          setSelectedRow([]);
          setSelectedRowPacijentZaSpajanje([]);
        });
    });
  };
  const razdvojiPacijente = (pacijentSpoji: any) => {
    if (!pacijentSpoji) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_RAZDVAJANJA_PACIJENTA, "");
      return;
    }
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.PACIJENT_RAZDVOJI, { pacijentSpojenoID: pacijentSpoji.keyPacijentSpojeno }, axiosConfig(authData.token))
        .then(() => {
          showGrowlMessage(
            MessageType.SUCCESS,
            Labels.TITLE_MESSAGES_USPESNO_PACIJENT_RAZDVAJANJE,
            pacijentSpoji.imeNovi + Labels.SPACE + pacijentSpoji.prezimeNovi + ", " + pacijentSpoji.imeStari + Labels.SPACE + pacijentSpoji.prezimeStari
          );
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_RAZDVAJANJA_PACIJENTA, "");
          console.log(error);
        })
        .finally(() => {
          setSelectPacijentSpojen(undefined);
          setSelectedRowPacijentSpojen([]);
        });
    });
  };

  const parseDateOfBirth = (column: any, columnName: any) => {
    const { datumRodjenja } = column;
    return datumRodjenja ? moment(new Date(datumRodjenja)).format(DATE_FORMAT) : null;
  };

  const parseDateOfBirthWithColumnName = (column: any, columnName: any) => {
    return column[columnName] ? moment(new Date(column[columnName])).format(DATE_FORMAT) : null;
  };

  const leftContentsSpajanje = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_PACIJENT_SPOJI} icon="pi pi-plus" className="p-button-success" onClick={() => spojiPacijente(selectedPacijent, selectedPacijentZaSpajanje)} />
    </React.Fragment>
  );

  const leftContentsRazdvajanje = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_PACIJENT_RAZDVOJI} icon="pi pi-plus" className="p-button-success" onClick={() => razdvojiPacijente(selectPacijentSpojen)} />
    </React.Fragment>
  );
  const searchFieldOnChangePacijent = (e: any) => {
    return setUnosiPoljaZaPretraguPacijenta({ ...unosiPoljaZaPretraguPacijenta, [e.target.name]: e.target.value });
  };

  const searchFieldOnChangePacijentZaSpajanje = (e: any) => {
    return setUnosiPoljaZaPretraguPacijentaZaSpajanje({ ...unosiPoljaZaPretraguPacijentaZaSpajanje, [e.target.name]: e.target.value });
  };
  const searchFieldOnChangePacijentSpojeni = (e: any) => {
    return setUnosiPoljaZaPretraguPacijentaSpojeni({ ...unosiPoljaZaPretraguSpojeni, [e.target.name]: e.target.value });
  };
  const createSearchField = ({ type, name, placeholder }: PrikaziPoljaZaPretraguType, searchFieldOnChange: any, unosiPoljaZaPretragu: any) => {
    if (type === "text") {
      return <InputText name={name} className="w-full" placeholder={placeholder} onChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} />;
    } else if (type === "number") {
      return <InputNumber name={name} className="w-full" placeholder={placeholder} onValueChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} />;
    } else {
      return <Dropdown name={name} className="w-full" placeholder={placeholder} optionLabel="name" onChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} options={optionsPol} />;
    }
  };
  let headerGroupSpojeno = (
    <ColumnGroup>
      <Row>
        <Column header={Labels.LABEL_PACIJENT_TRENUTNI} colSpan={3} />
        <Column header={Labels.LABEL_PACIJENT_STARI} colSpan={3} />
      </Row>
      <Row>
        <Column header={Labels.COLUMN_HEADER_IME} />
        <Column header={Labels.COLUMN_HEADER_PREZIME} />
        <Column header={Labels.COLUMN_HEADER_DATUM_RODJENJA} />
        <Column header={Labels.COLUMN_HEADER_IME} />
        <Column header={Labels.COLUMN_HEADER_PREZIME} />
        <Column header={Labels.COLUMN_HEADER_DATUM_RODJENJA} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="panel-report">
      <TabView className="mt-3" renderActiveOnly={false}>
        <TabPanel header={Labels.TITLE_PANEL_CRUD_PACIJENT_SPOJI}>
          <Panel header={Labels.TITLE_PANEL_CRUD_PACIJENT_SPOJI} className="panel-user-list">
            <Toolbar left={leftContentsSpajanje} />
            <div>
              <div className="flex">
                <div className="">
                  <Panel className="h3" header={Labels.LABEL_PACIJENT_SPAJANJE_PRVI}>
                    <div className="grid">
                      {prikaziPoljaZaPretragu.map((polje: PrikaziPoljaZaPretraguType, i: number) => (
                        <div key={i} className="col-2">
                          {createSearchField(polje, searchFieldOnChangePacijent, unosiPoljaZaPretraguPacijenta)}
                        </div>
                      ))}
                      <div className="col-12">
                        <Button
                          className="p-button-danger p-button-outlined mr-3"
                          onClick={() => pretragaPacijenta(unosiPoljaZaPretraguPacijenta, setPacijentiList)}
                          label={Labels.BUTTON_PRETRAGA}
                          icon="pi pi-search"
                        />
                        {unosiPoljaZaPretraguPacijenta?.brojRezultatePretrage && (
                          <Dropdown
                            tooltip={Labels.TOOLTIP_BROJ_PACIJENT}
                            tooltipOptions={{ position: "top" }}
                            value={unosiPoljaZaPretraguPacijenta.brojRezultatePretrage}
                            name="brojRezultatePretrage"
                            options={brojRezultatePretrageOptions}
                            onChange={searchFieldOnChangePacijent}
                            optionLabel="name"
                          />
                        )}
                      </div>
                    </div>
                  </Panel>
                </div>
                <div className="">
                  <Panel className="h3" header={Labels.LABEL_PACIJENT_SPAJANJE_DRUGI}>
                    <div className="grid">
                      {prikaziPoljaZaPretragu.map((polje: PrikaziPoljaZaPretraguType, i: number) => (
                        <div key={i} className="col-2">
                          {createSearchField(polje, searchFieldOnChangePacijentZaSpajanje, setUnosiPoljaZaPretraguPacijentaZaSpajanje)}
                        </div>
                      ))}
                      <div className="col-12">
                        <Button
                          className="p-button-danger p-button-outlined mr-3"
                          onClick={() => pretragaPacijenta(unosiPoljaZaPretraguPacijentaZaSpajanje, setPacijentiZaSpajanjeList)}
                          label={Labels.BUTTON_PRETRAGA}
                          icon="pi pi-search"
                        />
                        {unosiPoljaZaPretraguPacijentaZaSpajanje?.brojRezultatePretrage && (
                          <Dropdown
                            tooltip={Labels.TOOLTIP_BROJ_PACIJENT}
                            tooltipOptions={{ position: "top" }}
                            value={unosiPoljaZaPretraguPacijentaZaSpajanje.brojRezultatePretrage}
                            name="brojRezultatePretrage"
                            options={brojRezultatePretrageOptions}
                            onChange={searchFieldOnChangePacijentZaSpajanje}
                            optionLabel="name"
                          />
                        )}
                      </div>
                    </div>
                  </Panel>
                </div>
              </div>
              <div className="flex flex-row justify-content-between">
                <div className="flex flex-column justify-content-start flex-1">
                  <DataTable
                    filterDisplay="row"
                    value={pacijentiList}
                    paginator={true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
                    rows={20}
                    rowsPerPageOptions={[5, 10, 20]}
                    selectionMode="single"
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    onRowSelect={(e) => {
                      setSelectedPacijent(e.data);
                    }}
                    /*
                    isDataSelectable={(rowData) => {
                      return selectedRowPacijentZaSpajanje === undefined || selectedRowPacijentZaSpajanje.length === 0 ? true : rowData.data.keyPacijent !== selectedRowPacijentZaSpajanje?.keyPacijent;
                    }}
                    */
                    alwaysShowPaginator={false}
                    emptyMessage={Labels.LABEL_NEMA_PODATAKA}
                  >
                    <Column className="column-align-left" field={"ime"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                    <Column className="column-align-left" field={"prezime"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                    <Column
                      className="column-align-left column-width-170px"
                      field={"datumRodjenja"}
                      body={parseDateOfBirth}
                      header={Labels.COLUMN_HEADER_DATUM_RODJENJA}
                      showFilterMenu={false}
                      filter
                      sortable
                    />
                  </DataTable>
                </div>
                <div className="flex flex-column justify-content-start flex-1">
                  <DataTable
                    filterDisplay="row"
                    value={pacijentiZaSpajanjeList}
                    paginator={true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
                    rows={20}
                    rowsPerPageOptions={[5, 10, 20]}
                    selectionMode="single"
                    selection={selectedRowPacijentZaSpajanje}
                    onSelectionChange={(e) => setSelectedRowPacijentZaSpajanje(e.value)}
                    onRowSelect={(e) => {
                      setSelectedPacijentZaSpajanje(e.data);
                    }}
                    /*
                    isDataSelectable={(rowData) => {
                      return selectedRow === undefined || selectedRow.length === 0 ? true : rowData.data.keyPacijent !== selectedRow?.keyPacijent;
                    }}
                    */
                    alwaysShowPaginator={false}
                    emptyMessage={Labels.LABEL_NEMA_PODATAKA}
                  >
                    <Column className="column-align-left" field={"ime"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                    <Column className="column-align-left" field={"prezime"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                    <Column
                      className="column-align-left column-width-170px"
                      field={"datumRodjenja"}
                      body={parseDateOfBirth}
                      header={Labels.COLUMN_HEADER_DATUM_RODJENJA}
                      showFilterMenu={false}
                      filter
                      sortable
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </Panel>
        </TabPanel>
        <TabPanel header={Labels.TITLE_PANEL_CRUD_PACIJENT_RAZDVOJI}>
          <Panel header={Labels.TITLE_PANEL_CRUD_PACIJENT_RAZDVOJI} className="panel-user-list">
            <Toolbar left={leftContentsRazdvajanje} />
            <div className="flex flex-row justify-content-between ">
              <div className="flex flex-column justify-content-start flex-1">
                <div className=" ">
                  <Panel className="h3" header={Labels.LABEL_PACIJENT_SPOJENI}>
                    <div className="grid">
                      {prikaziPoljaZaPretragu.map((polje: PrikaziPoljaZaPretraguType, i: number) => (
                        <div key={i} className="col-2">
                          {createSearchField(polje, searchFieldOnChangePacijentSpojeni, unosiPoljaZaPretraguSpojeni)}
                        </div>
                      ))}
                      <div className="col-12">
                        <Button
                          className="p-button-danger p-button-outlined mr-3"
                          onClick={() => pretragaPacijentaSpojeni(unosiPoljaZaPretraguSpojeni)}
                          label={Labels.BUTTON_PRETRAGA}
                          icon="pi pi-search"
                        />
                        {unosiPoljaZaPretraguSpojeni?.brojRezultatePretrage && (
                          <Dropdown
                            tooltip={Labels.TOOLTIP_BROJ_PACIJENT}
                            tooltipOptions={{ position: "top" }}
                            value={unosiPoljaZaPretraguSpojeni.brojRezultatePretrage}
                            name="brojRezultatePretrage"
                            options={brojRezultatePretrageOptions}
                            onChange={searchFieldOnChangePacijentSpojeni}
                            optionLabel="name"
                          />
                        )}
                      </div>
                    </div>
                  </Panel>
                </div>
                <DataTable
                  filterDisplay="row"
                  value={pacijentiSpojeniList}
                  paginator={true}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
                  rows={20}
                  rowsPerPageOptions={[5, 10, 20]}
                  selectionMode="single"
                  selection={selectedRowPacijentSpojen}
                  onSelectionChange={(e) => setSelectedRowPacijentSpojen(e.value)}
                  onRowSelect={(e) => {
                    setSelectPacijentSpojen(e.data);
                  }}
                  alwaysShowPaginator={false}
                  emptyMessage={Labels.LABEL_NEMA_PODATAKA}
                  headerColumnGroup={headerGroupSpojeno}
                >
                  <Column className="column-align-left" field={"imeNovi"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                  <Column className="column-align-left" field={"prezimeNovi"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                  <Column
                    className="column-align-left"
                    field={"datumRodjenjaNovi"}
                    header={Labels.COLUMN_HEADER_DATUM_RODJENJA}
                    body={(column: any) => parseDateOfBirthWithColumnName(column, "datumRodjenjaNovi")}
                    filterMatchMode="contains"
                    showFilterMenu={false}
                    filter
                    sortable
                  />
                  <Column className="column-align-left" field={"imeStari"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                  <Column className="column-align-left" field={"prezimeStari"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
                  <Column
                    className="column-align-left"
                    field={"datumRodjenjaStari"}
                    header={Labels.COLUMN_HEADER_DATUM_RODJENJA}
                    body={(column: any) => parseDateOfBirthWithColumnName(column, "datumRodjenjaStari")}
                    filterMatchMode="contains"
                    showFilterMenu={false}
                    filter
                    sortable
                  />
                </DataTable>
              </div>
            </div>
          </Panel>
        </TabPanel>
      </TabView>
    </div>
  );
}
