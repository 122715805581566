let mapDataUNedelji = ["1_Ponedeljak", "2_Utorak", "3_Sreda", "4_Četvrtak", "5_Petak", "6_Subota", "7_Nedelja"];

let objectDanUNedelji = {
  1: "Ponedeljak",
  2: "Utorak",
  3: "Sreda",
  4: "Četvrtak",
  5: "Petak",
  6: "Subota",
  7: "Nedelja",
};

module.exports = {
  mapDataUNedelji: mapDataUNedelji,
  objectDanUNedelji: objectDanUNedelji,
};
