import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { RegistarController } from "../../../controllers/RegistarController";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { getObjectOperator } from "../../../utils/AUTSOperator";
import EnumTipovaVrednostiAtributa from "../../../utils/EnumTipovaVrednostiAtributa";
import Routers from "../../../utils/Routers";
import { registarID, setRegistarID, useEffectOnce } from "../../../utils/Utils";
import { findBrojRezultataByCode } from "../../../utils/EnumBrojRezultataPretrage";
import { AppContext } from "../../Store";
import axios from "axios";

interface PrikaziPoljaZaPretraguType {
  type: string;
  name: string;
  visible: boolean;
  placeholder: string;
}

interface RegistarIdType {
  id?: string;
}

export default function RegistarLogical() {
  const empty: any = {};
  const history = useHistory();
  const { id } = useParams<RegistarIdType>();
  localStorage.setItem("odabraniRegistar", JSON.stringify(id).toString());
  setRegistarID(id?.toString() ?? "");
  const {
    axiosParametarRegistarList,
    axiosRegistarById,
    axiosPretraga,
    axiosTipSkupaAtributaByRegistar,
    axisGetAtributUTipuSkupaByRegistar,
    axiosBrojUnosaPoPacijentu,
    axiosTipSkupaAtributaByTipSkupaAtributa,
    axiosEnumStavkaByAUTS,
    axiosAtributUTipuSkupaFindTVAByAUTS,
    axiosRegistarPacijentPolja,
  } = RegistarController();

  const registar = useRef<any>();
  const { showGrowlMessage, setShowBlockUI } = useContext(AppContext);
  const [dataTable, setDataTable] = useState();
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState<number>(20);
  const [first, setFirst] = useState<number>(0);

  const [tipoviSkupaAtributa, setTipoviSkupaAtributa] = useState<any>([]);

  const [displayPacijent, setDisplayPacijent] = useState<boolean>(false);
  const [displayUnos, setDisplayUnos] = useState<boolean>(false);

  const [selectedPacijent, setSelectedPacijent] = useState<any>();

  const [kriterijumZaPretragu, setKriterijumZaPretragu] = useState<any>();
  const [unosiPoljaZaPretragu, setUnosiPoljaZaPretragu] = useState<any>();
  const [prikaziPoljaZaPretragu, setPrikaziPoljaZaPretragu] = useState<PrikaziPoljaZaPretraguType[]>([]);

  const [podaci, setPodaci] = useState({
    autsiPoTSA: empty,
    autsiPoTSAFull: {},
    enumiPoAUTS: [],
    operatoriPoTSA: empty,
  });

  const [state, setState] = useState<any>({
    redPretrageElementi: empty,
    brojRedova: 0,
    initValue: empty,
    selectTipSkupAtributa: "",
    odabirDatumaPreUnosa: false,
    vremeUnosa: new Date(),
  });

  useEffectOnce(() => {
    fetchData();
    pretraga();
  });

  const fetchData = () => {
    setShowBlockUI(true);
    const reqRegistarById = axiosRegistarById();
    const reqTipSkupaAtributa = axiosTipSkupaAtributaByRegistar();
    const reqAtributUTipuSkupa = axisGetAtributUTipuSkupaByRegistar();
    const reqParametarRegistarList = axiosParametarRegistarList();
    const reqRegistarPacijentPolja = axiosRegistarPacijentPolja();
    axios
      .all([reqRegistarById, reqTipSkupaAtributa, reqAtributUTipuSkupa, reqParametarRegistarList, reqRegistarPacijentPolja])
      .then(
        axios.spread((resRegistarById: any, resTipSkupaAtributa: any, resAtributUTipuSkupa: any, resParametarRegistarList: any, resRegistarPacijentPolja: any) => {
          registar.current = resRegistarById.data;

          const { tipHijerarhijeAtributas } = resTipSkupaAtributa.data._embedded;
          let optionsTHA = tipHijerarhijeAtributas?.map((tha: any) => {
            return { value: tha.id, label: tha.naziv };
          });
          setTipoviSkupaAtributa(optionsTHA);

          responseAUTS(resAtributUTipuSkupa, 0);

          const { brojRezultata, odabirDatumaPregledaPreUnosa, brojRezultataUPretrazi } = resParametarRegistarList.data;
          brojRezultata && handleRowsAndFirst(brojRezultata, undefined);
          setUnosiPoljaZaPretragu({ ...unosiPoljaZaPretragu, brojRezultatePretrage: findBrojRezultataByCode(brojRezultataUPretrazi) });
          setState({ ...state, odabirDatumaPreUnosa: odabirDatumaPregledaPreUnosa });
        })
      )
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const isValidAdvancedSearch = () => {
    let isValid = true;
    const allRows = state.redPretrageElementi;
    Object.keys(allRows).forEach((key, i) => {
      const vrednostJSON = allRows[key];
      if (Object.keys(vrednostJSON).length === 0) {
        delete allRows[key];
      } else {
        const poljeVrednost = state.initValue[key];
        const { atributUTipuSkupa, operator, veza } = vrednostJSON;
        if (!(poljeVrednost && atributUTipuSkupa && operator) || (i > 0 && !veza)) {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_POPUNITE_DODATNE_PARAMETRE_PRETRAGE, "");
          isValid = false;
          return;
        }
      }
    });
    return isValid;
  };

  const pretraga = () => {
    if (!isValidAdvancedSearch()) {
      return;
    }

    setLoading(true);
    const paramsPretraga = {
      ime: unosiPoljaZaPretragu?.ime ?? "",
      prezime: unosiPoljaZaPretragu?.prezime ?? "",
      godinaOd: unosiPoljaZaPretragu?.godinaOd ?? "",
      godinaDo: unosiPoljaZaPretragu?.godinaDo ?? "",
      pol: unosiPoljaZaPretragu?.pol ?? "",
      jmbg: unosiPoljaZaPretragu?.jmbg ?? "",
      lbo: unosiPoljaZaPretragu?.lbo ?? "",
      brojRezultatePretrage: unosiPoljaZaPretragu?.brojRezultatePretrage?.code,
      redPretrage: state.redPretrageElementi,
      initValue: state.initValue,
    };

    axiosPretraga(paramsPretraga)
      .then((response: any) => {
        handleRowsAndFirst(undefined, 0);
        const { unosi, parametri } = response.data;

        const parseUnosi = unosi.map((row: any) => {
          return {
            ...row,
            poslednjiUnos: row.poslednjiUnosNaziv ? row.poslednjiUnosNaziv + " (" + row.poslednjiUnosDatum + ")" : "",
            prviUnos: row.prviUnosNaziv ? row.prviUnosNaziv + " (" + row.prviUnosDatum + ")" : "",
          };
        });
        setDataTable(parseUnosi);

        setKriterijumZaPretragu({
          kriterijumZaPretraguGodinaDo: parametri.kriterijumZaPretraguGodinaDo,
          kriterijumZaPretraguGodinaOd: parametri.kriterijumZaPretraguGodinaOd,
          kriterijumZaPretraguIme: parametri.kriterijumZaPretraguIme,
          kriterijumZaPretraguJMBG: parametri.kriterijumZaPretraguJMBG,
          kriterijumZaPretraguLBO: parametri.kriterijumZaPretraguLBO,
          kriterijumZaPretraguPol: parametri.kriterijumZaPretraguPol,
          kriterijumZaPretraguPrezime: parametri.kriterijumZaPretraguPrezime,
          prikazTekucihUnosaKodPretrage: parametri.prikazTekucihUnosaKodPretrage,
        });

        const prikaziPoljaZaPretraguList: PrikaziPoljaZaPretraguType[] = [
          { type: "text", name: "ime", visible: parametri.kriterijumZaPretraguIme, placeholder: Labels.PLACEHOLDER_IME },
          { type: "text", name: "prezime", visible: parametri.kriterijumZaPretraguPrezime, placeholder: Labels.PLACEHOLDER_PREZIME },
          { type: "text", name: "jmbg", visible: parametri.kriterijumZaPretraguJMBG, placeholder: Labels.PLACEHOLDER_JMBG },
          { type: "text", name: "lbo", visible: parametri.kriterijumZaPretraguLBO, placeholder: Labels.PLACEHOLDER_LBO },
          { type: "number", name: "godinaOd", visible: parametri.kriterijumZaPretraguGodinaOd, placeholder: Labels.PLACEHOLDER_GODINA_OD },
          { type: "number", name: "godinaDo", visible: parametri.kriterijumZaPretraguGodinaDo, placeholder: Labels.PLACEHOLDER_GODINA_DO },
          { type: "drop", name: "pol", visible: parametri.kriterijumZaPretraguPol, placeholder: Labels.PLACEHOLDER_POL },
        ];
        setPrikaziPoljaZaPretragu(prikaziPoljaZaPretraguList.filter((polje: PrikaziPoljaZaPretraguType) => polje.visible));
      })
      .catch((e: any) => console.log(e))
      .finally(() => setLoading(false));
  };

  const noviUnos = () => {
    const { odabirDatumaPreUnosa, vremeUnosa } = state;
    let unosID = state.selectTipSkupAtributa;
    if (odabirDatumaPreUnosa && !vremeUnosa) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_MORATE_ODABRATI_DATUM_PREGLEDA, "");
      return;
    }
    if (unosID === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_MORATE_ODABRATI_NEKI_OD_UNOSA, "");
      return;
    }
    axiosBrojUnosaPoPacijentu({
      thaID: state.selectTipSkupAtributa,
      pacijentID: selectedPacijent,
    })
      .then((response: any) => {
        if (response.data.isValid) {
          history.push({
            pathname: Routers.UNOSI_NOVI,
            state: {
              tsaID: state.selectTipSkupAtributa,
              pacijentID: selectedPacijent,
              vremeUnosa: odabirDatumaPreUnosa ? vremeUnosa.toString() : undefined,
            },
          });
        } else {
          showGrowlMessage(MessageType.ERROR, Labels.MESSAGES_NIJE_MOGUCE_KREIRATI_UNOS_JER_PREMASUJE_MAKSIMALAN_BROJ_MOGUCIH_KREIRANIH_PO_PACIJENTU + "(" + response.data.maxInstanci + ")", "");
          return;
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const responseAUTS = (response: any, key: any) => {
    const { atributUTipuSkupas } = response.data._embedded;
    let optionsAUTS = atributUTipuSkupas
      .filter((x: any) => x.tipVrednostiAtributaNumVrednost <= EnumTipovaVrednostiAtributa.DATUMSKI)
      .map((auts: any) => {
        let key = auts.id;
        let naziv = auts.naziv + (!kriterijumZaPretragu?.prikazTekucihUnosaKodPretrage ? " (" + auts.tipSkupaAtributaNaziv + ")" : "");
        return { value: key, label: naziv };
      });
    setPodaci({
      ...podaci,
      autsiPoTSA: { ...podaci.autsiPoTSA, [key]: optionsAUTS },
      autsiPoTSAFull: { ...podaci.autsiPoTSAFull, [key]: atributUTipuSkupas },
    });
  };

  const getEnumStavkaByAUTS = (value: any, initValueSvi: any, operatoriPoTSASvi: any) => {
    axiosEnumStavkaByAUTS({ auts: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value })
      .then((response: any) => {
        setState({
          ...state,
          initValue: initValueSvi,
        });
        setPodaci({
          ...podaci,
          operatoriPoTSA: operatoriPoTSASvi,
          enumiPoAUTS: response.data._embedded.enumStavkas,
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const changeState = (stateName: any, stateKey: any, stateValue: any) => {
    if (Array.isArray(stateName)) {
      stateName.map((elem: any) => {
        const nameObj = elem[0]; //initValue
        let stateOne = state[nameObj];
        if (stateOne !== undefined) {
          stateOne = { ...stateOne, ...{ [elem[1]]: elem[2] } };
          setState({ ...state, [nameObj]: stateOne });
        }
      });
    } else {
      let stateSvi = state[stateName];
      if (stateSvi !== undefined) {
        let key = parseInt(stateKey);
        Object.assign(stateSvi, { [key]: stateValue });
        setState({
          ...state,
          [stateName]: stateSvi,
        });
      }
    }
  };

  const dodajRedPretrage = () => {
    var brReda = state.brojRedova + 1;
    setState({
      ...state,
      redPretrageElementi: { ...state.redPretrageElementi, ...{ [brReda]: {} } },
      brojRedova: brReda,
    });
  };

  const obrisiRedPretrage = (key: any) => {
    const sviRedovi = { ...state.redPretrageElementi };
    delete sviRedovi[key];
    setState({ ...state, redPretrageElementi: sviRedovi });
  };

  const changeTipaSkupaAtributa = (key: any, value: any) => {
    setPodaci({ ...podaci, autsiPoTSA: { ...podaci.autsiPoTSA, [key]: [{ value: 1, label: "Podaci se učitavaju...", disabled: true }] } });
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { tipSkupaAtributa: { value: value } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
    axiosTipSkupaAtributaByTipSkupaAtributa({ tipSkupaAtributa: Endpoint.TIP_SKUPA_ATRIBUTA + "/" + value })
      .then((response: any) => {
        responseAUTS(response, key);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const changeAtributUTipuSkupa = (key: any, value: any) => {
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { atributUTipuSkupa: { value: value } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
    axiosAtributUTipuSkupaFindTVAByAUTS({
      atributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value,
    })
      .then((response: any) => {
        let tipVrednosti = response.data._embedded.tipVrednostiAtributas[0];
        Object.assign(redoviPretrage[key], { tipVrednosti: tipVrednosti });
        setState({ ...state, redPretrageElementi: redoviPretrage });
        let objectOperator = getObjectOperator(tipVrednosti.numerickaVrednost);
        let operatoriPoTSASvi = podaci.operatoriPoTSA;
        operatoriPoTSASvi = { ...operatoriPoTSASvi, ...{ [key]: objectOperator } };
        let initValueSvi = state.initValue;
        initValueSvi = {
          ...initValueSvi,
          ...{ [key]: "" },
        };
        getEnumStavkaByAUTS(value, initValueSvi, operatoriPoTSASvi);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const changeOperator = (key: any, value: any) => {
    const label = value.originalEvent.target.innerText;
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { operator: { value: value.value, label: label } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
  };

  const changeVeza = (key: any, value: any) => {
    const label = value.originalEvent.target.innerText;
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { veza: { label: label, value: value.value } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
  };

  const searchFieldOnChange = (e: any) => {
    return setUnosiPoljaZaPretragu({ ...unosiPoljaZaPretragu, [e.target.name]: e.target.value });
  };

  const handleKeyPressLogIn = (event: any) => event.key === "Enter" && pretraga();

  const handleRowsAndFirst = (rows: any, first: any) => {
    rows !== undefined && setTableRows(rows);
    first !== undefined && setFirst(first);
  };

  const openDialogUnos = (pacijentId: any) => {
    setDisplayUnos(true);
    setSelectedPacijent(pacijentId);
  };

  const openDialogPacijent = () => setDisplayPacijent(true);
  const closeDialogPacijent = () => setDisplayPacijent(false);
  const closeDialogUnos = () => setDisplayUnos(false);

  return {
    registar,
    state,
    setState,
    podaci,
    dataTable,
    loading,
    tableRows,
    first,
    handleRowsAndFirst,
    tipoviSkupaAtributa,
    displayPacijent,
    displayUnos,
    openDialogUnos,
    openDialogPacijent,
    closeDialogUnos,
    closeDialogPacijent,
    kriterijumZaPretragu,
    searchFieldOnChange,
    handleKeyPressLogIn,
    unosiPoljaZaPretragu,
    prikaziPoljaZaPretragu,
    pretraga,
    noviUnos,
    changeState,
    changeVeza,
    changeTipaSkupaAtributa,
    changeAtributUTipuSkupa,
    changeOperator,
    dodajRedPretrage,
    obrisiRedPretrage,
  };
}

export type { PrikaziPoljaZaPretraguType };
