import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Tree } from "primereact/tree";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import Routers from "../../utils/Routers";
import { AppContext } from "../Store";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT, NEW_DATE_TIME_FORMAT, registarID, useEffectOnce } from "./../../utils/Utils";
import { RadioButton } from "primereact/radiobutton";
import { RegistarController } from "../../controllers/RegistarController";

export default function PacijentFormulari({ odabirDatumaPreUnosa }: any) {
  const { id } = useParams<any>();
  const history = useHistory();
  const { authData, showGrowlMessage } = useContext(AppContext);
  const { token } = authData;
  const [displayUnos, setDisplayUnos] = useState<boolean>(false);
  const [selectedPacijent, setSelectedPacijent] = useState<any>();
  const [tipoviSkupaAtributa, setTipoviSkupaAtributa] = useState<any>([]);
  const { axiosTipSkupaAtributaByRegistar } = RegistarController();
  const [expandedKeys, setExpandedKeys] = useState({});
  const [state, setState] = useState({
    columnsUnosi: [],
    nazivUnosa: "",
    vremeUnosa: undefined,
    tsID: undefined,
    showDialog: false,
    selectTipSkupAtributa: "",
    odabirDatumaPreUnosaDialog: true,
  });
  const openDialogUnos = (pacijentId: any) => {
    setDisplayUnos(true);
    setSelectedPacijent(pacijentId);
  };

  const closeDialogUnos = () => {
    setDisplayUnos(false);
    setState({ ...state, selectTipSkupAtributa: "", vremeUnosa: undefined });
  };
  useEffectOnce(() => {
    axios
      .get(Endpoint.GET_UNOSI_TABELA, axiosConfig(token, { pacijentID: id, registarID: registarID }))
      .then((response) => {
        const treeData = parseDataTree(response.data);
        setState({ ...state, columnsUnosi: treeData });
        expandAll(treeData);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const noviUnos = (unosID: any) => {
    if (odabirDatumaPreUnosa && !state.vremeUnosa) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_MORATE_ODABRATI_DATUM_PREGLEDA, "");
      return;
    }

    axios
      .get(Endpoint.BROJ_UNOSA_PO_PACIJENTU, axiosConfig(token, { thaID: unosID, pacijentID: id }))
      .then((response) => {
        if (!response.data.isValid) {
          showGrowlMessage(MessageType.ERROR, Labels.MESSAGES_PREMASEN_MAX_BROJ_PO_PACIJENTU + " - ", response.data.maxInstanci);
        }
        history.push({
          pathname: Routers.UNOSI_NOVI,
          state: {
            tsaID: unosID,
            pacijentID: id,
            vremeUnosa: odabirDatumaPreUnosa ? moment(state.vremeUnosa).format(NEW_DATE_TIME_FORMAT) : undefined,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseChildrenTree = (children: any, index1: number) => {
    const { unosi } = children;
    if (!unosi || unosi.length === 0) return [];
    return unosi.map((d: any, index2: number) => {
      return {
        level: 2,
        key: index1 + "-" + index2,
        id: d.id,
        label: d.datum,
        popunjeniRelevantni: d.popunjeniRelevantni,
        radnikOdgovoran: d.radnikOdgovoran,
        webServiceKreirao: d.webServiceKreirao,
      };
    });
  };

  const parseDataTree = (tsaList: any) => {
    return tsaList
      .filter((tsa: any, i: number) => parseChildrenTree(tsa, i).length > 0)
      .map((tsa: any, index1: number) => {
        return {
          level: 1,
          key: index1,
          id: tsa.id,
          label: tsa.naziv,
          mozeSeInstancirati: tsa.mozeSeInstancirati,
          children: parseChildrenTree(tsa, index1),
        };
      });
  };

  useEffectOnce(() => {
    fetchData();
  });
  const fetchData = () => {
    axiosTipSkupaAtributaByRegistar()
      .then((response: any) => {
        const { tipHijerarhijeAtributas } = response.data._embedded;
        let optionsTHA = tipHijerarhijeAtributas?.map((tha: any) => {
          return { value: tha.id, label: tha.naziv };
        });
        setTipoviSkupaAtributa(optionsTHA);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const nodeTemplate = (node: any, options: any) => {
    let { level, label, mozeSeInstancirati } = node;
    if (level === 1) {
      return (
        <div className={`grid-nogutter flex p-1 w-11 align-items-center font-bold`}>
          <div className="col">{label}</div>
          <div className="col text-right">
            {mozeSeInstancirati && (
              <Button
                icon="pi pi-plus"
                className="p-button-outlined pac-icon"
                onClick={() => (odabirDatumaPreUnosa ? setState({ ...state, showDialog: true, tsID: node.id, nazivUnosa: label }) : noviUnos(node.id))}
              />
            )}
          </div>
        </div>
      );
    } else if (level === 2) {
      return (
        <div className={`${options.className} flex`}>
          <div className={(node.popunjeniRelevantni ? "relevantni" : "nerelevantni") + " " + (node.webServiceKreirao ? "bg-blue-800" : "")}>{label}</div>
          <div className="pac-lekar">{node.radnikOdgovoran}</div>
        </div>
      );
    }
  };
  const hideDialog = () => setState({ ...state, showDialog: false });

  const otvoriUnosTip = () => {
    if (!state.selectTipSkupAtributa) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_MORATE_ODABRATI_NEKI_OD_UNOSA, "");
      return;
    }
    noviUnos(state.selectTipSkupAtributa);
    setState({ ...state, showDialog: false });
  };

  const otvoriUnos = () => {
    noviUnos(state.tsID);
    setState({ ...state, showDialog: false });
  };

  const renderFooter = () => (
    <div>
      <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" onClick={hideDialog} className="p-button-warning" />
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-check" onClick={otvoriUnos} autoFocus />
    </div>
  );

  const renderFooterTipUnosa = () => (
    <div>
      <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" onClick={closeDialogUnos} className="p-button-warning" />
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-check" onClick={otvoriUnosTip} autoFocus />
    </div>
  );

  const template = (options: any) => {
    const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
    return (
      <div className={`${options.className} justify-content-between p-0`}>
        <div className="col-1">
          <Button icon={toggleIcon} className={options.togglerClassName} onClick={options.onTogglerClick} />
        </div>
        <div className={`${options.titleClassName} col-11 flex `}>
          <div className="col-6 align-content-center">{Labels.TITLE_MESSAGES_FORMULARI}</div>
          <div className="col-6 text-right">
            <Button
              className={"btn btn-outline-danger pac-icon text-right col p-button-icon-only"}
              icon="fas fa-plus"
              onClick={() => {
                openDialogUnos(id);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const expandAll = (array: any) => {
    let _expandedKeys = {};
    for (let node of array) {
      expandNode(node, _expandedKeys);
    }
    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const createExaminationList = tipoviSkupaAtributa?.map(({ value, label }: any) => {
    return (
      <div key={value} className="field-radiobutton col-12 py-0">
        <RadioButton
          inputId={value}
          name="category"
          value={state.selectTipSkupAtributa}
          onChange={(e) => setState({ ...state, selectTipSkupAtributa: value })}
          checked={state.selectTipSkupAtributa === value}
        />
        <label className="p-2" htmlFor={value}>
          {label}
        </label>
      </div>
    );
  });
  return (
    <React.Fragment>
      <Panel headerTemplate={template} toggleable>
        <Tree
          className="border-none"
          nodeTemplate={nodeTemplate}
          onToggle={(e) => setExpandedKeys(e.value)}
          expandedKeys={expandedKeys}
          value={state.columnsUnosi}
          selectionMode="single"
          onSelect={(e: any) => e.node.level === 2 && history.push(Routers.UNOSI + "/" + e.node.id)}
        />
      </Panel>
      <Dialog header={Labels.TITLE_DIALOG_KREIRANJE_UNOSA} className="w-3" visible={displayUnos} footer={renderFooterTipUnosa} onHide={closeDialogUnos}>
        <div className="grid">
          {state.odabirDatumaPreUnosaDialog && (
            <>
              <label className="col-12">{Labels.LABEL_DATUM_PREGLEDA}:</label>
              <Calendar className="col-12" showIcon dateFormat={CALENDAR_DATE_FORMAT} value={state.vremeUnosa} onChange={(e: any) => setState({ ...state, vremeUnosa: e.value })} />
            </>
          )}
          <label className="col-12">{Labels.LABEL_IZABERITE_TIP_PREGLEDA}:</label>
          {createExaminationList}
        </div>
      </Dialog>
      <Dialog header={Labels.TITLE_DIALOG_KREIRANJE_STAVKE_UNOSA + " - " + state.nazivUnosa} visible={state.showDialog} style={{ width: "30vw" }} footer={renderFooter()} onHide={hideDialog}>
        <div className="grid">
          <div className="col">{Labels.LABEL_DATUM_PREGLEDA}</div>
          <div className="col">
            <Calendar
              showIcon
              placeholder={Labels.PLACEHOLDER_DATUM_OD}
              dateFormat={CALENDAR_DATE_FORMAT}
              value={state.vremeUnosa}
              onChange={(selected: any) => setState({ ...state, vremeUnosa: selected.value })}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
