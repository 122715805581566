import React from "react";
import { Route, Switch } from "react-router-dom";
import Routers from "../../utils/Routers";
import CrudKategorijeIzvestajaList from "./izvestaji/CrudKategorijeIzvestajaList";
import CrudStatistickiIzvestajiList from "./izvestaji/CrudStatistickiIzvestajiList";
import CrudTipoviStatistickogIzvestajaList from "./izvestaji/CrudTipoviStatistickogIzvestajaList";
import CrudTipParametraStatistickogIzvestajaList from "./izvestaji/CrudTipParametraStatistickogIzvestajaList";
import Log from "./log/Log";
import RUParametri from "./parametri/RUParametri";
import CrudRadnikLista from "./radnik/CrudRadnikLista";
import CrudRegistar from "./registar/CrudRegistar";
import CrudRegistarList from "./registar/CrudRegistarList";
import CrudSponzorLista from "./sponzor/CrudSponzorLista";
import StatistikaUnosa from "./statistikaUnosa/StatistikaUnosa";
import CrudTipAtributaList from "./tipAtributa/CrudTipAtributaList";
import CrudUnos from "./tipHijerarhijeAtributa/CrudUnos";
import CrudUlogaList from "./uloga/CrudUlogaList";
import Upozorenja from "./upozorenja/Upozorenja";
import CrudUstanovaList from "./ustanova/CrudUstanovaList";
import Verzija from "./verzija/Verzija";
import PacijentSpajanjeList from "./pacijent/PacijentSpajanjeLista";

export default function AdminRouter() {
  return (
    <div>
      <Switch>
        <Route exact path={Routers.ADMIN_KATEGORIJE_IZVESTAJA} component={CrudKategorijeIzvestajaList} />
        <Route exact path={Routers.ADMIN_IZVESTAJI} component={CrudStatistickiIzvestajiList} />
        <Route exact path={Routers.ADMIN_TIPOVI_PARAMETARA_IZVESTAJA} component={CrudTipParametraStatistickogIzvestajaList} />
        <Route exact path={Routers.ADMIN_PARAMETRI} component={RUParametri} />
        <Route exact path={Routers.ADMIN_RADNICI} component={CrudRadnikLista} />
        <Route exact path={Routers.ADMIN_PACIJENT_SPAJANJE} component={PacijentSpajanjeList} />
        <Route exact path={Routers.ADMIN_REGISTRI} component={CrudRegistarList} />
        <Route exact path={`${Routers.ADMIN_REGISTAR}/:id?`} component={CrudRegistar} />
        <Route exact path={Routers.ADMIN_TIPOVI_IZVESTAJA} component={CrudTipoviStatistickogIzvestajaList} />
        <Route exact path={Routers.ADMIN_ULOGE} component={CrudUlogaList} />
        <Route exact path={Routers.ADMIN_USTANOVE} component={CrudUstanovaList} />
        <Route exact path={`${Routers.ADMIN_FORMUALR}/:id?`} component={CrudUnos} />
        <Route exact path={Routers.ADMIN_TIPOVI_ATRIBUTA} component={CrudTipAtributaList} />
        <Route exact path={Routers.ADMIN_LOG} component={Log} />
        <Route exact path={Routers.ADMIN_STATISTIKA_UNOSA} component={StatistikaUnosa} />
        <Route exact path={Routers.ADMIN_UPOZORENJA} component={Upozorenja} />
        <Route exact path={Routers.ADMIN_VERZIJE} component={Verzija} />
        <Route exact path={Routers.ADMIN_SPONZORI} component={CrudSponzorLista} />
      </Switch>
    </div>
  );
}
