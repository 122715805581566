import axios from "axios";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import { axiosConfig, DATE_FORMAT, registarID, useEffectOnce } from "./../../utils/Utils";
import Routers from "../../utils/Routers";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { AppContext } from "../Store";
import { Button } from "primereact/button";
import MessageType from "../../infrastructure/MessageType";
import { confirmDialog } from "primereact/confirmdialog";
import CUDialogPacijent from "../pacijent/CUDialogPacijent";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
interface Pacijent {
  id: string | null;
  ime: string | null;
  prezime: string | null;
  imeRoditelja: string | null;
  datumRodjenja: string | null;
  jmbg: string | null;
  lbo: string | null;
  polTrenutni: string | null;
  polNaRodjenju: string | null;
  adresa: string | null;
  brojKartona: string | null;
  kontaktTelefon: string | null;
  email: string | null;
  mobilniTelefon: string | null;
  zanimanje: string | null;
  strucnaSprema: string | null;
  radnoMesto: string | null;
  medicinskaNapomena: string | null;
  administrativnaNapomena: string | null;
}
export default function PacijentInfo({ dosije }: any) {
  const { id } = useParams<any>();
  const history = useHistory();
  const { authData, showGrowlMessage } = useContext(AppContext);
  const { token } = authData;

  const [openDialog, setOpenDialog] = useState(false);
  const [pacijent, setPacijent] = useState<Pacijent>({
    id: "",
    ime: "",
    prezime: "",
    imeRoditelja: "",
    datumRodjenja: "",
    jmbg: "",
    lbo: "",
    polTrenutni: "",
    polNaRodjenju: "",
    adresa: "",
    brojKartona: "",
    kontaktTelefon: "",
    email: "",
    mobilniTelefon: "",
    zanimanje: "",
    strucnaSprema: "",
    radnoMesto: "",
    medicinskaNapomena: "",
    administrativnaNapomena: "",
  });

  const loadPacijent = () => {
    axios
      .get(Endpoint.PACIJENT + "/" + id, axiosConfig(token))
      .then((response) => setPacijent(response.data))
      .catch((error) => console.log(error));
  };

  useEffectOnce(() => {
    loadPacijent();
  });

  const obrisiPacijenta = () => {
    const params = { aktivan: false };

    axios.patch(Endpoint.PACIJENT + "/" + id, params, axiosConfig(token)).then((response) => {
      showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISAN_PACIJENT, "");
      history.push(`${Routers.REGISTRI}/${registarID}`);
    });
  };

  const confirm = () => {
    confirmDialog({
      message: Labels.LABEL_BRISETE_PACIJENTA,
      header: Labels.TITLE_DIALOG_BRISANJE_PACIJENTA,
      acceptClassName: "p-button-danger",
      acceptLabel: Labels.LABEL_DA,
      rejectLabel: Labels.LABEL_NE,
      accept: obrisiPacijenta,
    });
  };

  const getPolString = (pol: string | null) => {
    return pol ? Labels.LABEL_MUSKI : Labels.LABEL_ZENSKI;
  };
  const checkExists = (variable: string | null) => {
    if (variable !== null && variable != undefined && variable !== "") {
      return true;
    }
    return false;
  };
  const leftColumn = [
    { label: Labels.LABEL_IME_I_PREZIME, value: pacijent.ime + " " + pacijent.prezime, visible: true },
    { label: Labels.LABEL_IME_RODITELJA, value: pacijent.imeRoditelja, visible: dosije.prikazPacijentImeRoditelja },
    { label: Labels.LABEL_DATUM_RODJENJA, value: pacijent.datumRodjenja ? moment.utc(pacijent.datumRodjenja).format(DATE_FORMAT) : undefined, visible: true },
    { label: Labels.LABEL_JMBG, value: pacijent.jmbg, visible: true },
    { label: Labels.LABEL_LBO, value: pacijent.lbo, visible: true },
    { label: Labels.LABEL_BROJ_KARTONA, value: pacijent.brojKartona, visible: true },
    { label: Labels.LABEL_EMAIL, value: pacijent.email, visible: true },
    { label: Labels.LABEL_MEDICINSKA_NAPOMENA, value: pacijent.medicinskaNapomena, visible: dosije.prikazPacijentMedicinskaNapomena },
    { label: Labels.LABEL_ADMINISTRATIVNA_NAPOMENA, value: pacijent.administrativnaNapomena, visible: dosije.prikazPacijentAdministrativnaNapomena },
  ];
  const rightColumn = [
    { label: Labels.LABEL_MOBILNI_TELEFON, value: pacijent.mobilniTelefon, visible: true },
    { label: Labels.COLUMN_HEADER_KONTAKT_TELEFON, value: pacijent.kontaktTelefon, visible: true },
    { label: Labels.LABEL_ADRESA, value: pacijent.adresa, visible: true },
    { label: Labels.LABEL_POL, value: checkExists(pacijent.polTrenutni) && getPolString(pacijent.polTrenutni), visible: true },
    {
      label: Labels.LABEL_POL_NA_RODJENJU,
      value: checkExists(pacijent.polNaRodjenju) ? getPolString(pacijent?.polNaRodjenju) : checkExists(pacijent.polTrenutni) && getPolString(pacijent.polTrenutni),
      visible: dosije.prikazPacijentPrikazJednogPola,
    },
    { label: Labels.LABEL_ZANIMANJE, value: pacijent.zanimanje, visible: true },
    { label: Labels.LABEL_STRUCNA_SPREMA, value: pacijent.strucnaSprema, visible: true },
    { label: Labels.LABEL_RADNO_MESTO, value: pacijent.radnoMesto, visible: dosije.prikazPacijentRadnoMesto },
  ];

  const createRow = (key: number, label: string, value: any) => {
    return (
      <div key={key} className="grid flex-column">
        <div className="col font-bold">
          <label className="mb-0">{label}</label>
        </div>
        <div className="col pac-value">
          <label className="mb-0">{value}</label>
        </div>
      </div>
    );
  };

  const template = (options: any) => (
    <div className={`${options.className} justify-content-between p-0`}>
      <div className="col-1">
        <Button icon={`pi pi-chevron-${options.collapsed ? "down" : "up"}`} className={options.togglerClassName} onClick={options.onTogglerClick} />
      </div>
      <div className={`${options.titleClassName} col-7`}>{Labels.LABEL_PODACI_O_PACIJENTU}</div>
      <div className="col-5 text-right flex">
        {!dosije.blurPodataka && <Button icon="pi pi-user-edit" onClick={() => setOpenDialog(true)} className="p-button-outlined pac-icon" />}
        <Button icon="pi pi-trash" onClick={confirm} className="p-button-outlined ml-2 pac-icon" />
        <Button icon="pi pi-file" onClick={() => history.push(`/pacijentArhiva/${id}`)} className="p-button-outlined ml-2 pac-icon" />
      </div>
    </div>
  );

  const closeDialog = () => setOpenDialog(false);

  return (
    <React.Fragment>
      <Panel headerTemplate={template} toggleable>
        <div className="grid">
          <div className="col">{leftColumn.map((p, i) => p.visible && createRow(i, p.label, p.value))}</div>
          <div className="col">{rightColumn.map((p, i) => p.visible && createRow(i, p.label, p.value))}</div>
        </div>
      </Panel>
      <div className="layout-pacijent">
        <Dialog header={Labels.TITLE_DIALOG_IZMENI_PACIJENTA} className="w-5" visible={openDialog} onHide={closeDialog}>
          <CUDialogPacijent pacijentOperation={EntityOperation.UPDATE} onCancel={closeDialog} pacijent={pacijent} afterSubmit={loadPacijent} />
        </Dialog>
      </div>
    </React.Fragment>
  );
}
