import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useContext } from "react";
import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import Routers from "../../../utils/Routers";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputSwitch } from "primereact/inputswitch";

interface CrudRegistarProp {
  registarOperation: string;
}

interface PacijentPolje {
  id: number;
  naziv: string;
  sifra: string;
  obavezno: boolean;
  _links: any;
}

export default function CrudRegistar() {
  const location = useLocation<CrudRegistarProp>();
  const history = useHistory();
  const { authData } = useContext(AppContext);

  const { id } = useParams<any>();

  const registarOperation = location.state ? location.state.registarOperation : EntityOperation.UPDATE;

  const [spisakUnosa, setSpisakUnosa] = useState();

  const [spisakPacijentPolja, setSpisakPacijentPolja] = useState<Array<PacijentPolje>>();

  const [registarChange, setRegistarChange] = useState<Partial<any>>();

  const messages = useRef<any>(null);

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedUnos, setSelectedUnos] = useState<any>();

  const pacijentPoljeChangedMap = useRef<Map<number, PacijentPolje>>(new Map<number, PacijentPolje>());

  const home = { icon: "pi pi-home", url: "/" };
  const items = [
    { label: Labels.LABEL_REGISTRI, url: "/admin/registri" },
    { label: registarOperation === EntityOperation.READ ? Labels.LABEL_DETALJI_O_REGISTRU + registarChange?.naziv : Labels.LABEL_IZMENA_REGISTRA + registarChange?.naziv },
  ];
  const itemsCreate = [{ label: Labels.LABEL_REGISTRI, url: "/admin/registri" }, { label: Labels.LABEL_KREIRANJE_REGISTRA }];

  useEffectOnce(() => {
    if (id) {
      const requestRegistar = axios.get(`${Endpoint.REGISTAR}/${id}`, axiosConfig(authData.token));
      const requestSpisakUnosa = axios.get(
        Endpoint.TIP_HIJERARHIJE_ATRIBUTA + "/search/findByAktivanAndRegistar",
        axiosConfig(authData.token, { registar: `${Endpoint.REGISTAR}/${id}`, aktivan: true, page: 0, size: 50000000 })
      );
      const requestSpisakPacijentPolja = axios.get(
        Endpoint.REGISTAR_PACIJENT_POLJE + "/search/findByRegistarAndAktivan",
        axiosConfig(authData.token, { registar: `${Endpoint.REGISTAR}/${id}`, aktivan: true })
      );
      axios
        .all([requestRegistar, requestSpisakUnosa, requestSpisakPacijentPolja])
        .then(
          axios.spread((responseRegistar: any, responseSpisakUnosa: any, responseSpisakPacijentPolja: any) => {
            setRegistarChange(responseRegistar.data);
            setSpisakUnosa(responseSpisakUnosa.data._embedded.tipHijerarhijeAtributas);
            setSpisakPacijentPolja(responseSpisakPacijentPolja.data._embedded.registarPacijentPoljes);
          })
        )
        .catch((error: any) => {
          showMessage(Labels.TITLE_MESSAGES_GRESKA, MessageType.ERROR, error);
        });
    }
  });

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const validateInput = (message: string) => {
    if (!registarChange || !registarChange.naziv) {
      showMessage(message, MessageType.ERROR, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
      return;
    }

    axios
      .post(Endpoint.REGISTAR, registarChange, axiosConfig(authData.token))
      .then(() => {
        showMessage(Labels.TITLE_MESSAGES_USPESNO_KREIRANO, MessageType.SUCCESS, registarChange?.naziv);
        history.push(Routers.ADMIN_REGISTRI);
      })
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, MessageType.SUCCESS, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
      return;
    }
    axios
      .patch(Endpoint.REGISTAR + `/${registarChange?.id}`, registarChange, axiosConfig(authData.token))
      .then(() => {
        showMessage(Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, MessageType.SUCCESS, registarChange?.naziv);
      })
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, MessageType.ERROR, error);
      });
  };

  const onDelete = () => {
    axios
      .patch(
        Endpoint.REGISTAR + `/${registarChange?.id}`,
        {
          aktivan: false,
        },
        axiosConfig(authData.token)
      )
      .then(() => {
        showMessage(Labels.TITLE_MESSAGES_USPESNO_OBRISANO, MessageType.SUCCESS, registarChange?.naziv);
        history.push(Routers.ADMIN_REGISTRI);
      })
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, MessageType.ERROR, error);
      });
  };

  const onCancel = () => {
    history.push(Routers.ADMIN_REGISTRI);
  };
  const onPacijentPoljeUpdate = () => {
    axios
      .all(
        Array.from(pacijentPoljeChangedMap.current).map(([index, value]) => {
          axios.patch(Endpoint.REGISTAR_PACIJENT_POLJE + `/${value?.id}`, value, axiosConfig(authData.token));
        })
      )
      .then(() => {
        showMessage(Labels.TITLE_MESSAGES_PACIJENT_POLJA_PROMENJENO, MessageType.SUCCESS, "");
      })
      .finally(() => {
        pacijentPoljeChangedMap.current.clear();
      });
  };

  const openUnos = (entityOperation: string) => {
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        break;
    }
    const id = entityOperation !== EntityOperation.CREATE ? selectedUnos.id : "";

    history.push({
      pathname: `/admin/formular/${id}`,
      state: { unosOperation: entityOperation, registarID: registarChange?.id, registarOperation: registarOperation },
    });
  };

  if (!id && !location.state) {
    return <Redirect to="/admin/registri" />;
  }

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openUnos(EntityOperation.CREATE)} />
      <Button disabled={!selectedUnos} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openUnos(EntityOperation.UPDATE)} />
      <Button disabled={!selectedUnos} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openUnos(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button disabled={!selectedUnos} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openUnos(EntityOperation.DELETE)} />
    </React.Fragment>
  );

  const leftContentsTools = () => (
    <React.Fragment>
      {registarOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={onCreate} />}
      {registarOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onUpdate} />}
      {registarOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={onDelete} />}
    </React.Fragment>
  );

  const leftPacijentPoljeContentsTools = () => (
    <React.Fragment>
      {registarOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onPacijentPoljeUpdate} />}
    </React.Fragment>
  );

  const rightContentsTools = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
    </React.Fragment>
  );

  return (
    <div className="layout-registri">
      <Panel header={Labels.TITLE_PANEL_REGISTARA} className="panel-registri-list">
        <BreadCrumb model={registarOperation === EntityOperation.CREATE ? itemsCreate : items} home={home} />
        <Messages ref={messages} />
        <TabView renderActiveOnly={false}>
          <TabPanel header={Labels.TAB_REGISTAR}>
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_NAZIV}</div>
                <div className="col-4">
                  <InputText
                    disabled={isFormDisabled(registarOperation)}
                    value={registarChange && registarChange.naziv}
                    onChange={(e: any) => {
                      setRegistarChange({
                        ...registarChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_SIFRA}</div>
                <div className="col-4">
                  <InputText
                    disabled={isFormDisabled(registarOperation)}
                    value={registarChange && registarChange.sifra}
                    onChange={(e: any) => {
                      setRegistarChange({
                        ...registarChange,
                        sifra: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {registarOperation !== EntityOperation.CREATE && (
              <div className="fluid">
                <div className="field grid align-items-center">
                  <div className="col-2">{Labels.LABEL_PRVI_UNOS}</div>
                  <div className="col-4">
                    <Dropdown
                      disabled={isFormDisabled(registarOperation)}
                      dataKey="id"
                      value={registarChange && registarChange.nazivPrviUnos ? registarChange.nazivPrviUnos : null}
                      options={spisakUnosa}
                      editable={true}
                      onChange={(e: any) => {
                        setRegistarChange({
                          ...registarChange,
                          nazivPrviUnos: e.value ? e.value.naziv : "",
                          prviTipHijerarhijeAtributa: e.value ? Endpoint.TIP_HIJERARHIJE_ATRIBUTA + "/" + e.value.id : null,
                        });
                      }}
                      className="p-dropdown"
                      optionLabel="naziv"
                      filter={true}
                      filterBy="naziv"
                      style={{ width: "100%" }}
                      showClear={registarChange && registarChange.nazivPrviUnos}
                    />
                  </div>
                </div>
              </div>
            )}
            {registarOperation !== EntityOperation.CREATE && (
              <div className="fluid">
                <div className="field grid align-items-center">
                  <div className="col-2">{Labels.LABEL_PODRAZUMEVANI_UNOS}</div>
                  <div className="col-4">
                    <Dropdown
                      disabled={isFormDisabled(registarOperation)}
                      dataKey="id"
                      value={registarChange && registarChange.nazivPodrazumevaniUnos ? registarChange.nazivPodrazumevaniUnos : ""}
                      options={spisakUnosa}
                      editable={true}
                      onChange={(e: any) => {
                        setRegistarChange({
                          ...registarChange,
                          nazivPodrazumevaniUnos: e.value ? e.value.naziv : "",
                          podrazumevaniTipHijerarhijeAtributa: e.value ? Endpoint.TIP_HIJERARHIJE_ATRIBUTA + "/" + e.value.id : null,
                        });
                      }}
                      optionLabel="naziv"
                      filter={true}
                      filterBy="naziv"
                      style={{ width: "100%" }}
                      showClear={registarChange && registarChange.nazivPodrazumevaniUnos}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_OPIS}</div>
                <div className="col-4">
                  <InputText
                    disabled={isFormDisabled(registarOperation)}
                    value={registarChange && registarChange.opis}
                    onChange={(e: any) => {
                      setRegistarChange({
                        ...registarChange,
                        opis: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <Toolbar left={leftContentsTools} right={rightContentsTools} />
          </TabPanel>
          <TabPanel header={Labels.TAB_FORMULARI} disabled={registarOperation === EntityOperation.CREATE}>
            <Toolbar left={leftContents} right={rightContents} />
            <DataTable
              value={spisakUnosa}
              paginator={true}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
              rows={20}
              rowsPerPageOptions={[5, 10, 20]}
              selectionMode="single"
              selection={selectedRow}
              onSelectionChange={(e) => setSelectedRow(e.value)}
              onRowSelect={(e) => setSelectedUnos(e.data)}
              alwaysShowPaginator={false}
              emptyMessage={Labels.LABEL_NEMA_PODATAKA}
            >
              <Column className="column-align-left column-width-200px" field={"prikazniRedosled"} header={Labels.COLUMN_HEADER_PRIKAZNI_REDNOSLED} filter sortable />
              <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filter sortable />
              <Column className="column-align-left" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} filter sortable />
              <Column className="column-align-left" field={"napomena"} header={Labels.COLUMN_HEADER_NAPOMENA} filter sortable />
            </DataTable>
          </TabPanel>
          <TabPanel header={Labels.TAB_OBAVEZNA_POLJA} disabled={registarOperation === EntityOperation.CREATE}>
            {registarOperation !== EntityOperation.CREATE && spisakPacijentPolja && (
              <>
                {spisakPacijentPolja.map((pacijentPolje: PacijentPolje, index: number) => {
                  return (
                    <div className="fluid" key={index}>
                      <div className="field grid align-items-center">
                        <div className="col-2">{pacijentPolje.naziv}</div>
                        <div className="col-4">
                          <InputSwitch
                            disabled={registarOperation !== EntityOperation.UPDATE}
                            checked={pacijentPolje?.obavezno ?? false}
                            onChange={(e) => {
                              spisakPacijentPolja[index].obavezno = e.target.value;
                              setSpisakPacijentPolja([...spisakPacijentPolja]);
                              if (pacijentPoljeChangedMap.current.get(index) !== undefined) {
                                pacijentPoljeChangedMap.current.delete(index);
                              } else {
                                pacijentPoljeChangedMap.current.set(index, spisakPacijentPolja[index]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {registarOperation === EntityOperation.UPDATE && <Toolbar left={leftPacijentPoljeContentsTools} />}
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
