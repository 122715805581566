const EnumPacijentPolje = {
  IME: "ime",
  PREZIME: "prezime",
  POL: "pol",
  DATUM_RODJENJA: "datum_rodjenja",
  JMBG: "jmbg",
  LBO: "lbo",
  ADRESA: "adresa",
  KONTAKT_TELEFON: "kontakt_telefon",
  EMAIL: "email",
  ZANIMANJE: "zanimanje",
};

export default EnumPacijentPolje;
