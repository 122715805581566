import axios from "axios";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Menubar } from "primereact/menubar";
import { TieredMenu } from "primereact/tieredmenu";
import React, { useContext, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Endpoint from "../endpoints/Endpoint";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../infrastructure/MessageType";
import { AplikativnaPrava } from "../utils/AplikativnaPravaLista";
import { axiosConfig, useEffectOnce } from "../utils/Utils";
import { AppContext } from "./Store";
import Routers from "../utils/Routers";

export default function Header() {
  const { showGrowlMessage, nazivAplikacije, authData, updateAuthData, dispatch, deleteAuthData } = useContext(AppContext);

  const [selectedUstanova, setSelectedUstanova] = useState();
  const [ustanove, setUstanove] = useState([]);
  const [pravoAdministriranja, setPravoAdministriranja] = useState(false);
  const [pravoZaIzvestaje, setPravoZaIzvestaje] = useState(false);
  const [menuAdminItems, setMenuAdminItems] = useState<any>([]);
  const menu = useRef<any>(null);

  const history = useHistory();

  const getItem = (name: string, path: string) => {
    return {
      label: name,
      command: () => {
        openPage(path);
      },
    };
  };

  useEffectOnce(() => {
    if (authData) {
      const requestRadnikUstanova = axios.get(
        Endpoint.RADNIK_USTANOVA + "/search/findUstanovaByAktivanAndRadnik",
        axiosConfig(authData.token, {
          radnik: Endpoint.RADNIK + "/" + authData.id,
          aktivan: true,
        })
      );
      const requestApliaktivanaPrava = axios.get(
        Endpoint.APLIKATIVNO_PRAVO + "/search/findByRadnik/",
        axiosConfig(authData.token, {
          radnik: "api/radnici/" + authData.id,
        })
      );

      axios
        .all([requestRadnikUstanova, requestApliaktivanaPrava])
        .then(
          axios.spread((responseRadnikUstanova: any, responseAplikativnaPrava: any) => {
            setUstanove(responseRadnikUstanova.data._embedded.ustanovas);
            const apliativnaPrava = responseAplikativnaPrava.data._embedded.aplikativnoPravoes;
            const imaAplPravaID = apliativnaPrava.map((aplPravo: any) => {
              return aplPravo.numerickaVrednost;
            });
            const odabranaUstanova = responseRadnikUstanova.data._embedded.ustanovas.find((x: any) => x.naziv === authData.ustanovaNaziv);
            setSelectedUstanova(odabranaUstanova);

            const administriranjeUloga = imaAplPravaID.includes(AplikativnaPrava.administriranje_uloga);
            const administriranjeUstanova = imaAplPravaID.includes(AplikativnaPrava.administriranje_ustanova);
            const administriranjeRadnika = imaAplPravaID.includes(AplikativnaPrava.administriranje_radnika);
            const administriranjeRegistara = imaAplPravaID.includes(AplikativnaPrava.administriranje_registara);
            const administriranjeParametara = imaAplPravaID.includes(AplikativnaPrava.administriranje_parametara);
            const administriranjeIzvestaja = imaAplPravaID.includes(AplikativnaPrava.administriranje_izvestaja);
            const administriranjePregledaLoga = imaAplPravaID.includes(AplikativnaPrava.administriranje_pregleda_loga);
            const administriranjeUpozorenja = imaAplPravaID.includes(AplikativnaPrava.administriranje_upozorenja);
            const administriranjeStatistikeUnosa = imaAplPravaID.includes(AplikativnaPrava.administriranje_statistike_unosa);
            const administriranjeSpajanjaPacijenata = imaAplPravaID.includes(AplikativnaPrava.administriranje_spajanja_pacijenta);

            const radSaIzvestajima = imaAplPravaID.includes(AplikativnaPrava.rad_sa_izvestajima);

            const administriranje =
              administriranjeUloga ||
              administriranjeUstanova ||
              administriranjeRadnika ||
              administriranjeRegistara ||
              administriranjeParametara ||
              administriranjeIzvestaja ||
              administriranjePregledaLoga ||
              administriranjeStatistikeUnosa ||
              administriranjeUpozorenja;
            const itemsAdmin = [];
            if (administriranjeUloga) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_ULOGE, Routers.ADMIN_ULOGE));
            }
            if (administriranjeUstanova) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_USTANOVE, Routers.ADMIN_USTANOVE));
            }
            if (administriranjeRadnika) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_RADNICI, Routers.ADMIN_RADNICI));
            }
            if (administriranjeRegistara) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_REGISTRI, Routers.ADMIN_REGISTRI));
              itemsAdmin.push(getItem(Labels.MENU_ITEM_TIPOVI_ATRIBUTA, Routers.ADMIN_TIPOVI_ATRIBUTA));
            }
            if (administriranjeParametara) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_PARAMETRI, Routers.ADMIN_PARAMETRI));
            }
            if (administriranjePregledaLoga) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_PREGLED_LOGA, Routers.ADMIN_LOG));
            }
            if (administriranjeUpozorenja) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_UPOZORENJA, Routers.ADMIN_UPOZORENJA));
              itemsAdmin.push(getItem(Labels.MENU_ITEM_VERZIJE, Routers.ADMIN_VERZIJE));
            }
            if (administriranjeStatistikeUnosa) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_STATISTIKA_UNOSA, Routers.ADMIN_STATISTIKA_UNOSA));
            }
            if (administriranjeIzvestaja) {
              const izvestajItems = [];
              izvestajItems.push(getItem(Labels.MENU_ITEM_KATEGORIJE_IZVESTAJA, Routers.ADMIN_KATEGORIJE_IZVESTAJA));
              izvestajItems.push(getItem(Labels.MENU_ITEM_TIPOVI_IZVESTAJA, Routers.ADMIN_TIPOVI_IZVESTAJA));
              izvestajItems.push(getItem(Labels.MENU_ITEM_AZURIRANJE_IZVESTAJA, Routers.ADMIN_IZVESTAJI));
              izvestajItems.push(getItem(Labels.MENU_ITEM_TIPOVI_PARAMETARA, Routers.ADMIN_TIPOVI_PARAMETARA_IZVESTAJA));
              itemsAdmin.push({
                label: Labels.MENU_ITEM_IZVESTAJI,
                items: izvestajItems,
              });
            }
            if (administriranjeSpajanjaPacijenata) {
              itemsAdmin.push(getItem(Labels.MENU_ITEM_SPAJANJE_PACIJENATA, Routers.ADMIN_PACIJENT_SPAJANJE));
            }
            itemsAdmin.push(getItem(Labels.MENU_ITEM_SPONZORI, Routers.ADMIN_SPONZORI));
            setMenuAdminItems(itemsAdmin);
            setPravoAdministriranja(administriranje);
            setPravoZaIzvestaje(radSaIzvestajima ?? false);
          })
        )
        .catch((error: any) => {
          showGrowlMessage(Labels.TITLE_MESSAGES_GRESKA, MessageType.ERROR, error);
        });
    }
  });

  const openPage = (path: string) => {
    history.push(path);
    menu.current.toggle();
  };

  const logOut = () => {
    if (authData) {
      deleteAuthData();
      window.location.reload();
    }
  };

  const changeUstanovaNaziv = (ustanova: any) => {
    axios
      .patch(Endpoint.RADNIK + "/" + authData.id, { ustanova: ustanova._links.self.href }, axiosConfig(authData.token))
      .then((response) => {
        setSelectedUstanova(ustanova);
        authData.ustanovaNaziv = ustanova.naziv;
        updateAuthData(authData);
        dispatch({ type: "setPacijentPretragaRefrash", pacijentPretragaRefrash: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Menubar
      className="layout-header"
      start={
        <div className="grid">
          <div className="col-3">
            <NavLink activeClassName="active-route" className="application-name" to="/">
              {nazivAplikacije}
            </NavLink>
          </div>
          <div className="col-9 justify-center layout-centre-container">
            <NavLink activeClassName="active-route" className="text" to="/registri">
              {Labels.MENU_REGISTRI}
            </NavLink>
            {pravoAdministriranja && (
              <div>
                <TieredMenu model={menuAdminItems} popup={true} ref={menu} id="overlay_tmenu" />
                <Button
                  label={Labels.MENU_ADMINISTRACIJA}
                  icon="pi pi-bars"
                  className="p-menu-button"
                  onClick={(event) => menu.current.toggle(event)}
                  aria-haspopup={true}
                  aria-controls="overlay_tmenu"
                />
              </div>
            )}
            {pravoZaIzvestaje && (
              <NavLink activeClassName="active-route" className="text" to="/izvestaji">
                {Labels.MENU_IZVESTAJI}
              </NavLink>
            )}
          </div>
        </div>
      }
      end={
        <div className="grid text-right layout-right-container">
          <div className="col-7">
            <Dropdown
              className="w-6 text-left"
              dataKey="id"
              value={selectedUstanova}
              options={ustanove}
              editable={false}
              onChange={(e) => {
                changeUstanovaNaziv(e.target.value);
              }}
              optionLabel="naziv"
              filter={true}
              filterBy="naziv"
            />
          </div>
          <div className="col-5 flex justify-content-end align-items-center">
            {authData && (
              <NavLink activeClassName="active-route" className="text text-small" to="/korisnickiProfil">
                {authData.imePrezime}
              </NavLink>
            )}
            <NavLink to="/">
              <Button label={Labels.LABEL_LOG_OUT} icon="pi pi-power-off" onClick={logOut} />
            </NavLink>
          </div>
        </div>
      }
    />
  );
}
